import { InboxIcon, SparklesIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import Link from 'next/link';

const Homev2 = () => {
  return (
    <div className="relative overflow-hidden bg-white pb-32 pt-16">
      <div className="relative">
        <div className="lg:mx-auto lg:grid lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:px-0 lg:py-16">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Safe Rides and Airport Transfers
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Safety is our top priority. Our professional drivers ensure
                  that you reach your destination safely, whether it's a
                  door-to-door transportation service or an{' '}
                  <Link href="/birmingham-airport-transfers">
                    <a className="text-secondary">airport transfer. </a>
                  </Link>
                  We also provide long-distance taxi services for family outings
                  or group events, enjoy a worry-free travel experiences.
                </p>
                <div className="mt-6">
                  <a
                    href="#"
                    className="inline-flex rounded-lg bg-secondary px-4 py-1.5 text-base font-semibold leading-7 text-primary shadow-sm ring-1 ring-primary hover:bg-secondary/75 hover:ring-primary"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;Excellent service from taxi driver with a last minute
                    booking to airport. Kept us updated re his journey to us &
                    arrival time. So polite & helpful.&rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src="https://lh3.googleusercontent.com/a/AAcHTtdaGmeJbF8EmMsraEeX3UJgz71bL848VpT8M5eV=w72-h72-p-rp-mo-br100"
                        alt="Google Review Link"
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      <a href="https://g.co/kgs/yXxdmL" target="_blank">
                        Louise Parker
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 flex items-center justify-center">
            <div className="relative w-full h-[400px] ">
              <Image
                layout='fill'
                objectFit='contain'
                quality={100}
                src="/assets/images/hires1.png"
                alt="Airport Transfers"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:grid lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:px-0 lg:py-32">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Corporate Travel and Personalised Taxi Service
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  We understand the unique requirements of corporate travel,
                  offering customised taxi service solutions to meet your
                  business needs. Our luxury taxis, private transfers, and
                  expert chauffeurs provide a seamless and comfortable
                  experience for all your corporate transportation requirements.
                </p>
                <div className="mt-6">
                  <a
                    href="#"
                    className="inline-flex rounded-lg bg-secondary px-4 py-1.5 text-base font-semibold leading-7 text-primary shadow-sm ring-1 ring-primary hover:bg-secondary/75 hover:ring-primary"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;It was a business pre booked trip, the driver George
                    very pleasant arrived on time and took me to my destination
                    safely.&rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src="https://lh3.googleusercontent.com/a/AAcHTteEh5X5GLMvPkTtIQIrbB6e7hVzFDa6lG_Jilmu=w120-h120-p-rp-mo-br100"
                        alt="Google Review Link"
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      <a href="https://g.co/kgs/qRKYLZ" target="_blank">
                        Shane Russell
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 flex items-center justify-center">
            <div className="relative w-full h-[400px] ">
              <Image
                layout='fill'
                objectFit='contain'
                quality={100}
                src="/assets/images/hires2.png"
                alt="Airport Transfers"
              />
            </div>
            {/* <div className="lg:relative lg:h-full">
              <Image
                height={500}
                width={800}
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full  "
                src="/assets/images/hires2.png"
                alt="Business Travel"
              />
            </div> */}
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="lg:mx-auto lg:grid lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:px-0 lg:py-16">
            <div>
              <div></div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Family and Group Transportation
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  From family outings to group events, our reliable rides and
                  spacious vehicles cater to all your group transportation
                  needs. With our personalised taxi service and exceptional
                  customer service, we ensure that every passenger enjoys a
                  comfortable and convenient travel experience.
                </p>
                <div className="mt-6">
                  <a
                    href="#"
                    className="inline-flex rounded-lg bg-secondary px-4 py-1.5 text-base font-semibold leading-7 text-primary shadow-sm ring-1 ring-primary hover:bg-secondary/75 hover:ring-primary"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;Very good service, drivers are polite and helpful,
                    have used 247 Radio cars for a long time and can't fault
                    them. Pleased with how the drivers can reasure me when I
                    have an appointment and always get me there on time,
                    regardless of traffic build up. Would Recommend them.&rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src="https://lh3.googleusercontent.com/a/AAcHTtdExZGOJkLOT8FJ1wJXPnySnNbCcJc8JtwSV9S4Yw=w120-h120-p-rp-mo-br100"
                        alt="Google Review Link"
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      <a href="https://g.co/kgs/cQ8yLr" target="_blank">
                        Lynn Tremelling
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="relative w-full h-[400px] ">
              <Image
                layout='fill'
                objectFit='contain'
                quality={100}
                src="/assets/images/hires3.png"
                alt="Airport Transfers"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
export default Homev2;
